import {FC} from 'react';
import {Button, Typography, Stack} from '@mui/material';

import {APIListHeaderProps} from './types';

export const APIListNavigation: FC<APIListHeaderProps> = ({previous, next, totalPages, page}) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Button onClick={previous} disabled={page <= 1}>
      Назад
    </Button>
    <Stack>
      <Typography align="center" variant="body2" fontSize={12}>{`Страница: ${page}`}</Typography>
      <Typography
        align="center"
        variant="body2"
        fontSize={12}
        color="grey.400"
      >{`Всего страниц: ${totalPages}`}</Typography>
    </Stack>
    <Button onClick={next} disabled={totalPages ? page >= totalPages : false}>
      Вперед
    </Button>
  </Stack>
);
