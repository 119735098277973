import {pick} from 'lodash';

import {Publications} from '@apiSchema/users-api';
import {yupObject, yupString, yupStringOfOptions} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {PublicationFormType} from './types';

const privacyOptions = [
  {
    name: 'Видна только пользователю',
    value: 'private',
  },
  {
    name: 'Видна команде',
    value: 'groups',
  },
  {
    name: 'Видна подписчикам',
    value: 'groups',
  },
  {
    name: 'Видна команде и подписчикам',
    value: 'groups',
  },
  {
    name: 'Видна всем',
    value: 'public',
  },
];
export const fields: FieldStructure[] = [
  {
    fields: [
      {
        type: 'dateTime',
        name: 'createdAt',
        label: 'Дата создания',
        disabled: true,
        onlyDate: true,
      },
      {
        type: 'select',
        name: 'privacy',
        label: 'Приватность',
        options: privacyOptions,
      },
    ],
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    disabled: true,
    options: [
      {
        name: 'Активная',
        value: 'active',
      },
      {
        name: 'Удаленная',
        value: 'deleted',
      },
    ],
  },
];

const initialValues: PublicationFormType = {
  createdAt: '',
  privacy: '',
  status: 'active',
};

export const getInitials = (post: Publications): PublicationFormType =>
  post
    ? {
        ...initialValues,
        ...pick(post, ['createdAt', 'privacy']),
        status: post.deletedAt ? 'deleted' : 'active',
      }
    : initialValues;

export const validation = yupObject({
  createdAt: yupString,
  privacy: yupStringOfOptions(privacyOptions),
  status: yupString,
});
