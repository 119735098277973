import {OnDragEndResponder} from '@hello-pangea/dnd';
import {useRef, useEffect, useState} from 'react';

export const useWhyDidYouUpdate = (name: string, props: Record<string, any>) => {
  const previousProps = useRef<Record<string, any>>({});
  useEffect(() => {
    if (previousProps.current) {
      const allKeys = Object.keys({...previousProps.current, ...props});
      const changesObj: Record<string, any> = {};
      allKeys.forEach(key => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log('[why-did-you-update]', name, changesObj);
      }
    }
    previousProps.current = props;
  });
};

export const usePositions = (originalPositions: string[], onPositionsChanged: (positions: string[]) => void) => {
  const [positions, setPositions] = useState<string[]>([]);

  useEffect(() => {
    setPositions(originalPositions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalPositions.join('.')]);

  const onDragEnd: OnDragEndResponder = data => {
    if (data.destination) {
      const newPositions = [...positions];
      newPositions.splice(data.source.index, 1);
      newPositions.splice(data.destination.index, 0, data.draggableId);
      setPositions(newPositions);
      onPositionsChanged(newPositions);
    }
  };

  return {positions, onDragEnd};
};
