import _ from 'lodash';

import {usersApi} from 'api';
import {FC, memo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import {skipToken} from '@reduxjs/toolkit/query';

import Stack from '@mui/material/Stack';
import {Publications, WidgetsInfoApiArg} from '@apiSchema/users-api';

import {Widget} from 'modules/widgets/containers';
import {selectPost} from 'modules/dashboard/selectors';
import {PostIdProp, StageIdProp} from 'modules/dashboard/types';
import {WidgetVisualContainer} from 'modules/widgets/containers/WidgetVisual';

const selectPostWidgetData = createSelector(selectPost, (post: Publications) => {
  const stage = post.attachments?.find(item => item.referenceName === 'Stages');
  if (!stage || !stage.widgets) return undefined;
  const widgetsInfoArg: WidgetsInfoApiArg = {
    _id: [],
    usersId: [post.userId!],
    containerName: 'publication',
    stagesId: [stage.referenceId],
  };
  stage.widgets.forEach(widget => {
    widgetsInfoArg._id?.push(widget.widgetId);
  });
  widgetsInfoArg._id = _.uniq(widgetsInfoArg._id);
  return widgetsInfoArg;
});

export const PublicationWidgetVisualContent: FC<PostIdProp & StageIdProp> = ({postId, stageId}) => {
  const {userId} = usersApi.endpoints.publicationIndex.useQuery(
    {postId},
    {
      selectFromResult: ({data}) => ({
        userId: data?.user?._id,
      }),
    }
  );

  if (!userId || !stageId) return null;
  return <WidgetVisualContainer stageId={stageId} userId={userId} />;
};

export const PublicationWidgetContent: FC<PostIdProp & StageIdProp> = memo(({postId, stageId}) => {
  const widgetsInfoArg = useSelector(state => selectPostWidgetData(state, postId), _.isEqual);
  const {isLoading} = usersApi.endpoints.widgetsInfo.useQuery(widgetsInfoArg ? widgetsInfoArg : skipToken);
  if (!widgetsInfoArg?._id?.length) return <PublicationWidgetVisualContent postId={postId} stageId={stageId} />;
  if (isLoading) return null;
  return (
    <Stack spacing={2}>
      {widgetsInfoArg._id.map(widgetId => (
        <Widget key={widgetId} widgetId={widgetId} {..._.omit(widgetsInfoArg, ['_id'])} />
      ))}
    </Stack>
  );
});
