import {Publications} from '@apiSchema/users-api';
import {skipToken} from '@reduxjs/toolkit/query';
import {usersApi} from 'api';
import IsExistsContainer from 'components/IsExistsContainer';
import {RouteObject, useParams} from 'react-router';
import {PublicationBody, PublicationStageInfo} from './containers/Publication';
import {Comments} from 'modules/comments';
import {Stack} from '@mui/material';
import {ScrollToAnchor} from 'components/ScrollToAnchor';

const useGetPublication = () => {
  const {postId} = useParams<'postId'>();
  return usersApi.usePublicationIndexQuery(postId ? {postId} : skipToken);
};

export const postRoute: RouteObject = {
  path: 'post/:postId',
  element: (
    <IsExistsContainer<Publications> useFetch={useGetPublication}>
      {post => (
        <div id="publication">
          <Stack spacing={2}>
            <ScrollToAnchor />
            <PublicationBody postId={post._id!} />
            <PublicationStageInfo postId={post._id!} />
            <Comments referenceName="Publications" referenceAuthorId={post.userId!} referenceId={post._id!} />
          </Stack>
        </div>
      )}
    </IsExistsContainer>
  ),
};
