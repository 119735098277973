import {isArray} from 'lodash';
import React, {useMemo} from 'react';
import {Box, CircularProgress, Stack} from '@mui/material';
import {NumberParam, useQueryParam, withDefault} from 'use-query-params';

import {generateShortHash} from 'utils';
import {useIsUserQueryExists} from './hook';
import {APIEndpoint, APIEndpointUseQuery, APIListProps, APINames, EndpointNames} from './types';

const LIMIT = 10;

function useList<APIName extends APINames, EndpointName extends EndpointNames[APIName], Item extends object = any>({
  params,
  getData,
  getTotal,
  useQuery,
  api,
  endpoint,
  limit: defaultLimit = LIMIT,
}: APIEndpoint<APIName, EndpointName, Item> &
  APIEndpointUseQuery<APIName, EndpointName> &
  Pick<APIListProps<Item>, 'limit'>) {
  const [offset, setOffset] = useQueryParam(
    `offset-${generateShortHash(`${api}.${endpoint as string}.${JSON.stringify(params)}`)}`,
    withDefault(NumberParam, 0)
  );
  const [limit] = useQueryParam(
    `limit-${generateShortHash(`${api}.${endpoint as string}.${JSON.stringify(params)}`)}`,
    withDefault(NumberParam, defaultLimit)
  );

  const response = useQuery({...(params as any), limit, offset: limit * offset} as any);
  const items = useMemo(() => {
    if (response.data && 'data' in (response.data as any)) return (response.data as any)?.data || [];
    if (!getData && isArray(response.data || [])) return response.data || [];
    if (getData) getData((response as any).data);
    return [];
  }, [response, getData]) as any[];

  const reset = () => setOffset(() => 0);
  const next = () => setOffset(offset => (offset || 0) + 1);
  const previous = () => setOffset(offset => (offset || 0) - 1);

  return {
    items,
    next,
    previous,
    reset,
    isFetching: response.isFetching,
    page: offset + 1,
    totalPages: getTotal ? Math.ceil((getTotal(response.data as any) || 0) / LIMIT) : undefined,
  };
}

const defaultKeyExtractor = (item: any) => item._id! as string;

function APIList<APIName extends APINames, EndpointName extends EndpointNames[APIName], Item extends object = any>({
  renderItem,
  keyExtractor = defaultKeyExtractor,
  spacing = 0,
  Header,
  Footer,
  enableOverlayLoader,
  ...props
}: APIEndpoint<APIName, EndpointName> & APIEndpointUseQuery<APIName, EndpointName> & APIListProps<Item>) {
  const {items, isFetching, ...rest} = useList<APIName, EndpointName, Item>(props as any);

  return (
    <Box position="relative">
      <Stack spacing={spacing}>
        {Header && <Header {...rest} />}
        {items.map((item, index) => (
          <React.Fragment key={keyExtractor(item)}>
            {renderItem({item, index, itemId: keyExtractor(item)})}
          </React.Fragment>
        ))}
        {Footer && <Footer {...rest} />}
      </Stack>
      {isFetching && enableOverlayLoader && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          bgcolor="rgba(255, 255, 255, .4)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export function UsersAPIList<
  APIName extends APINames,
  EndpointName extends EndpointNames[APIName],
  Item extends object = any
>(props: APIEndpoint<APIName, EndpointName> & APIListProps<Item>) {
  const {endpoint, api} = props;
  const useFetch = useIsUserQueryExists({endpoint, api});
  if (!useFetch) return null;
  return <APIList useQuery={useFetch as any} {...props} />;
}

export default UsersAPIList;
