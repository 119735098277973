import {concat} from 'lodash';

import {getListTags} from './utils';
import {usersApi} from './usersApi';
import {api as rawAdminApi} from './generated/admin-api';

export const adminApi = rawAdminApi.enhanceEndpoints({
  addTagTypes: [
    'Mailings',
    'Tags',
    'Users',
    'UsersPermissions',
    'UserLogins',
    'Groups',
    'GroupUsers',
    'Contents',
    'ContentTags',
    'ContentsCategories',
    'Scripts',
    'Tasks',
    'TasksBlocks',
    'TasksBlocksTasks',
    'TasksTimelines',
    'ScriptGroups',
    'ScriptActions',
    'Templates',
    'TemplateOptions',
    'StreamUsers',
    'Collectors',
    'CollectorsKeys',
    'TasksCategories',
    'Settings',
    'Quests',
    'QuestTags',
    'QuestPoints',
    'QuestsTimelines',
    'QuestPointsRewards',
    'QuestPointsTimelines',
    'Claims',
    'ClaimsData',
    'Blockers',
    'Access',
    'ReactionsBlocks',
    'PaymentsServices',
    'Rewards',
    'Widgets',
    'WidgetAttrs',
    'WidgetRules',
    'WidgetVisuals',
    'WidgetVisualsProps',
    'WidgetVisualsDesign',
    'Timecodes',
  ],
  endpoints: {
    // CONTRACTS
    contractRestore: {
      onQueryStarted: async (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res => {
          if ('data' in res) {
            dispatch(usersApi.util.upsertQueryData('contractsIndex', {userId: res.data.userId!}, res.data));
          }
        }),
    },
    contractDelete: {
      onQueryStarted: async (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res => {
          if ('data' in res) {
            dispatch(usersApi.util.upsertQueryData('contractsIndex', {userId: res.data.userId!}, res.data));
          }
        }),
    },
    // TIMECODES
    timecodesIndex: {
      providesTags: (_data, _error, args) => [{type: 'Timecodes', id: `file.${args.fileId}`}],
      onQueryStarted: async (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res => {
          res.data.data?.forEach(timecode =>
            dispatch(adminApi.util.upsertQueryData('timecodeIndex', {timecodeId: timecode._id!}, timecode))
          );
        }),
    },
    timecodesAdd: {
      invalidatesTags: (_data, _error, args) => [{type: 'Timecodes', id: `file.${args.timecodeDto.fileId}`}],
    },
    timecodeIndex: {
      providesTags: (_data, _error, args) => [{type: 'Timecodes', id: `tc.${args.timecodeId}`}],
    },
    timecodePatch: {
      // invalidatesTags: (_data, _error, args) => [{type: 'Timecodes', id: `tc.${args.timecodeId}`}],
      onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('timecodeIndex', {timecodeId: args.timecodeId}, draft => {
            Object.assign(draft, args.timecodePartialDto);
          })
        );
        queryFulfilled.catch(patch.undo);
      },
    },
    // ACHIVEMENTS
    achievementsAdd: {
      onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
          dispatch(usersApi.util.invalidateTags(['Achivements']));
        } catch (e) {}
      },
    },
    // WIDGETS
    widgetFillData: {
      onQueryStarted: ({widgetId}, {queryFulfilled, dispatch}) => {
        queryFulfilled.then(() => {
          dispatch(usersApi.util.invalidateTags([{type: 'WidgetsInfo', id: `widget.${widgetId}`}]));
        });
      },
    },
    widgetsIndex: {
      providesTags: data =>
        concat(
          [{type: 'Widgets', id: 'list'}],
          (data?.data || []).map(item => ({type: 'Widgets', id: `Widget.${item?._id}`}))
        ),
    },
    widgetsAdd: {
      invalidatesTags: [{type: 'Widgets', id: 'list'}],
    },
    widgetIndex: {
      providesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    widgetUpdate: {
      invalidatesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    widgetPatch: {
      invalidatesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    // WIDGET FORMULA
    widgetsFormulasIndex: {
      providesTags: () => [{type: 'Widgets', id: 'formula.list'}],
    },
    widgetsFormulasAdd: {
      invalidatesTags: () => [{type: 'Widgets', id: 'formula.list'}],
    },
    widgetFormulaIndex: {
      providesTags: data => [{type: 'Widgets', id: `formula.${data?._id!}`}],
    },
    widgetFormulaPatch: {
      invalidatesTags: data => [{type: 'Widgets', id: `formula.${data?._id!}`}],
    },
    widgetFormulaUpdate: {
      invalidatesTags: data => [{type: 'Widgets', id: `formula.${data?._id!}`}],
    },
    // WIDGET ATTRS
    widgetAttrsIndex: {
      providesTags: (_data, _meta, {widgetId}) => [{type: 'WidgetAttrs', id: `list.${widgetId}`}],
      onQueryStarted: ({widgetId}, {queryFulfilled, dispatch}) => {
        queryFulfilled.then(res =>
          res.data?.map(attr =>
            dispatch(adminApi.util.upsertQueryData('widgetAttrIndex', {widgetId, attrId: attr._id!}, attr))
          )
        );
      },
    },
    widgetAttrsAdd: {
      invalidatesTags: (_data, _meta, {widgetId}) => [{type: 'WidgetAttrs', id: `list.${widgetId}`}],
    },
    widgetAttrsChangePositions: {
      invalidatesTags: (_data, _meta, {widgetId}) => [{type: 'WidgetAttrs', id: `list.${widgetId}`}],
    },
    widgetAttrIndex: {
      providesTags: data => [{type: 'WidgetAttrs', id: `attr.${data?._id}`}],
    },
    widgetAttrDelete: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `attr.${data?._id}`}],
    },
    widgetAttrRestore: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `attr.${data?._id}`}],
    },
    widgetAttrUpdate: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `attr.${data?._id}`}],
    },
    widgetAttrPatch: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `attr.${data?._id}`}],
      onQueryStarted: ({widgetAttrPartialDto, ...args}, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('widgetAttrIndex', args, draft => {
            Object.assign(draft, widgetAttrPartialDto);
          })
        );
        queryFulfilled.catch(patch.undo);
      },
    },
    // WIDGET RULES
    widgetRulesIndex: {
      providesTags: (data, meta, {widgetId}) => [{type: 'WidgetRules', id: `widget.${widgetId}`}],
      onQueryStarted: async ({widgetId}, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(res => {
          res.data.map(rule =>
            dispatch(adminApi.util.upsertQueryData('widgetRuleIndex', {widgetId, ruleId: rule._id!}, rule))
          );
        });
      },
    },
    widgetRulesAdd: {
      invalidatesTags: (data, meta, {widgetId}) => [{type: 'WidgetRules', id: `widget.${widgetId}`}],
    },
    widgetRuleIndex: {
      providesTags: data => [{type: 'WidgetRules', id: data?._id}],
    },
    widgetRuleUpdate: {
      invalidatesTags: data => [{type: 'WidgetRules', id: data?._id}],
    },
    widgetRulePatch: {
      invalidatesTags: data => [{type: 'WidgetRules', id: data?._id}],
    },
    widgetRuleDelete: {
      invalidatesTags: (data, meta, {widgetId}) => [{type: 'WidgetRules', id: `widget.${widgetId}`}],
    },
    // WIDGET VISUALS
    widgetsVisualsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisuals', id: 'list'}],
          (data?.data || []).map(item => ({type: 'WidgetVisuals', id: `WidgetVisual.${item?._id}`}))
        ),
    },
    widgetsVisualIndex: {
      providesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    widgetsVisualsAdd: {
      invalidatesTags: [{type: 'WidgetVisuals', id: 'list'}],
    },
    widgetsVisualUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    widgetsVisualPatch: {
      invalidatesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    // WIDGET VISUALS PROPS
    widgetsVisualPropsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisualsProps', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${item?._id}`}))
        ),
    },
    widgetsVisualPropsAdd: {
      invalidatesTags: [{type: 'WidgetVisualsProps', id: `list`}],
    },
    widgetsVisualPropsChangePositions: {
      invalidatesTags: [{type: 'WidgetVisualsProps', id: `list`}],
    },
    widgetsVisualPropIndex: {
      providesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropPatch: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropDelete: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    // WIDGET VISUALS DESIGN
    widgetsVisualDesignsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisualsDesign', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${item?._id}`}))
        ),
    },
    widgetsVisualDesignsAdd: {
      invalidatesTags: [{type: 'WidgetVisualsDesign', id: `list`}],
    },
    widgetsVisualDesignsChangePositions: {
      invalidatesTags: [{type: 'WidgetVisualsDesign', id: `list`}],
    },
    widgetsVisualDesignIndex: {
      providesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignPatch: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignDelete: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    // REWARDS
    rewardsIndex: {
      providesTags: data =>
        concat(
          [{type: 'Rewards', id: 'list'}],
          (data?.data || []).map(item => ({type: 'Rewards', id: `Reward.${item?._id}`}))
        ),
    },
    rewardsAdd: {
      invalidatesTags: [{type: 'Rewards', id: 'list'}],
    },
    rewardIndex: {
      providesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    rewardUpdate: {
      invalidatesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    rewardPatch: {
      invalidatesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    // PAYMENTS SERVICES
    paymentsServicesIndex: {
      providesTags: data =>
        concat(['PaymentsServices'], data?.data?.map(item => ({type: 'PaymentsServices', id: item._id})) as any) as any,
    },
    paymentsServicesAdd: {
      invalidatesTags: data => ['PaymentsServices', {type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceIndex: {
      providesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServicePatch: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceUpdate: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceDelete: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    // CLAIMS
    claimsDataIndex: {
      providesTags: () => [{type: 'Claims', id: 'list'}],
    },
    claimsDataDetails: {
      providesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
      ],
    },
    claimsDataSetClaimed: {
      invalidatesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
        {type: 'Claims', id: 'list'},
      ],
    },
    claimsDataRejectClaims: {
      invalidatesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
        {type: 'Claims', id: 'list'},
      ],
    },
    // ACCESS
    accessIndex: {
      providesTags: () => [{type: 'Access', id: 'list'}],
    },
    accessAdd: {
      invalidatesTags: () => [{type: 'Access', id: 'list'}],
    },
    accessElemIndex: {
      providesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    accessElemPatch: {
      invalidatesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    accessElemUpdate: {
      invalidatesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    // BLOCKERS
    blockersIndex: {
      providesTags: () => [{type: 'Blockers', id: 'list'}],
    },
    blockerIndex: {
      providesTags: data => [{type: 'Blockers', id: data?._id}],
    },
    blockersAdd: {
      invalidatesTags: () => [{type: 'Blockers', id: 'list'}],
    },
    blockerUpdate: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    blockerPatch: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    blockerDelete: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    // QUEST TAGS
    questTagsSet: {
      invalidatesTags: (_, __, {questId}) => [{type: 'QuestTags', id: questId}],
    },
    questTagsIndex: {
      providesTags: (_, __, {questId}) => [{type: 'QuestTags', id: questId}],
    },
    // QUEST REWARDS
    questPointsRewardsIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointsRewardsAdd: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointRewardUpdate: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointRewardPatch: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    // QUEST POINT TIMELINES
    questPointsTimelinesIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPointsTimelines', id: pointId}],
    },
    questPointsTimelinesAdd: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsTimelines', id: pointId}],
    },
    // QUEST TIMELINE
    questTimelineElemRecalculateRewards: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemEmitSpecific: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemReset: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemDelete: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemEmit: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemStop: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemStart: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemRebuild: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemPatch: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemUpdate: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemIndex: {
      providesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelinesIndex: {
      providesTags: (data, __, {questId}) =>
        concat(
          [{type: 'QuestsTimelines', id: questId}],
          (data || []).map(item => ({type: 'QuestsTimelines', id: `timeline.${item._id}`}))
        ),
    },
    questTimelinesAdd: {
      invalidatesTags: (_, __, {questId}) => [{type: 'QuestsTimelines', id: questId}],
    },
    // QUEST POINTS
    questPointIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPoints', id: pointId}],
    },
    questPointPatch: {
      onQueryStarted: ({questPointPartialDto, ...args}, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('questPointIndex', args, draft => {
            Object.assign(draft, questPointPartialDto);
          })
        );
        queryFulfilled.catch(patch.undo);
      },
    },
    questPointUpdate: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPoints', id: pointId}],
    },
    // EVENTS
    questIndex: {
      providesTags: data => [{type: 'Quests', id: data?._id}],
    },
    questPatch: {
      invalidatesTags: (_, __, {questId}) => [{type: 'Quests', id: questId}],
    },
    questPointsIndex: {
      providesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
      onQueryStarted: (args, {queryFulfilled, dispatch}) => {
        queryFulfilled.then(res => {
          res?.data.map(questPoint =>
            dispatch(adminApi.util.upsertQueryData('questPointIndex', {pointId: questPoint._id!}, questPoint))
          );
        });
      },
    },
    questPointsAdd: {
      invalidatesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
    },
    questPointsChangePositions: {
      invalidatesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
    },
    // MAILINGS
    mailingsAdd: {
      invalidatesTags: ['Mailings'],
    },
    mailingActivate: {
      invalidatesTags: ['Mailings'],
    },
    mailingDisactivate: {
      invalidatesTags: ['Mailings'],
    },
    mailingUpdate: {
      invalidatesTags: (_, __, {mailingId}) => [{type: 'Mailings', id: mailingId}],
    },
    mailingIndex: {
      providesTags: (_, __, {mailingId}) => [{type: 'Mailings', id: mailingId}],
    },
    mailingsIndex: {
      providesTags: () => ['Mailings'],
    },
    // SETTINGS
    settingsIndex: {
      providesTags: ['Settings'],
    },
    settingsUpdate: {
      invalidatesTags: ['Settings'],
    },
    settingsPatch: {
      invalidatesTags: ['Settings'],
    },
    // TASK CATEGORIS
    tasksCategoriesIndex: {
      providesTags: getListTags('TasksCategories', (item: any) => item._id) as any,
    },
    tasksCategoriesAdd: {
      invalidatesTags: [{type: 'TasksCategories', id: '@@LIST'}],
    },
    tasksCategoryPatch: {
      invalidatesTags: (_, __, {categoryId}) => [{type: 'TasksCategories', id: categoryId}],
    },
    tasksCategoryUpdate: {
      invalidatesTags: (_, __, {categoryId}) => [{type: 'TasksCategories', id: categoryId}],
    },
    // COLLECTOR KEYS
    collectorKeysIndex: {
      providesTags: getListTags('CollectorsKeys', (item: any) => item._id) as any,
    },
    collectorKeysAdd: {
      invalidatesTags: [{type: 'CollectorsKeys', id: '@@LIST'}],
    },
    collectorKeyUpdate: {
      invalidatesTags: (_, __, {keyId}) => [{type: 'CollectorsKeys', id: keyId}],
    },
    // COLLECTORS
    collectorsIndex: {
      providesTags: getListTags('Collectors', (item: any) => item._id) as any,
    },
    collectorsAdd: {
      invalidatesTags: [{type: 'Collectors', id: '@@LIST'}],
    },
    collectorUpdate: {
      invalidatesTags: (_, __, {collectorId}) => [{type: 'Collectors', id: collectorId}],
    },
    // TASK ACCESS SETTING
    taskTimelinesIndex: {
      providesTags: getListTags('TasksTimelines', (item: any) => item._id) as any,
    },
    taskTimelinesAdd: {
      invalidatesTags: [{type: 'TasksTimelines', id: '@@LIST'}],
    },
    taskTimelineElemDelete: {
      invalidatesTags: [{type: 'TasksTimelines', id: '@@LIST'}],
    },
    taskTimelineElemPatch: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'TasksTimelines', id: timelineId}],
    },
    taskTimelineElemUpdate: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'TasksTimelines', id: timelineId}],
    },
    streamUsers: {
      providesTags: (data, meta, {streamId}) => [{type: 'StreamUsers', id: streamId}],
    },
    streamAddUsers: {
      invalidatesTags: (_, __, {streamId, body: {usersId}}) =>
        [
          {type: 'StreamUsers', id: streamId},
          {type: 'Users', id: `stream.${streamId}`},
        ].concat(usersId.map(userId => ({type: 'Users' as const, id: userId}))) as any,
      onQueryStarted: ({body: {usersId}}, {queryFulfilled, dispatch}) => {
        queryFulfilled.then(() =>
          dispatch(usersApi.util.invalidateTags(usersId.map(userId => ({type: 'Users', id: userId}))))
        );
      },
    },
    streamDeleteUser: {
      invalidatesTags: (_, __, {streamId}) => [
        {type: 'StreamUsers', id: streamId},
        {type: 'Users', id: `stream.${streamId}`},
      ],
    },
    // TASKS BLOCK
    blockTasksIndex: {
      providesTags: ['TasksBlocksTasks'],
    },
    blockTaskAdd: {
      invalidatesTags: ['TasksBlocksTasks'],
    },
    blockTaskDeleteTask: {
      invalidatesTags: ['TasksBlocksTasks'],
    },
    //
    blocksIndex: {
      providesTags: ['TasksBlocks'],
    },
    blocksAdd: {
      invalidatesTags: ['TasksBlocks'],
    },
    blockIndex: {
      providesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    blockPatch: {
      invalidatesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    blockUpdate: {
      invalidatesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    // TASKS
    taskIndex: {
      providesTags: data => [{type: 'Tasks', id: data?._id}],
    },
    taskUpdate: {
      invalidatesTags: (_, __, {taskId}) => [{type: 'Tasks', id: taskId}],
    },
    taskPatch: {
      invalidatesTags: (_, __, {taskId}) => [{type: 'Tasks', id: taskId}],
    },
    scriptActionsChangePositions: {
      invalidatesTags: (_data, _meta, {groupId}) => [{type: 'ScriptActions', id: `group.${groupId}`}],
    },
    scriptActionsIndex: {
      providesTags: (_data, _meta, {groupId}) => [{type: 'ScriptActions', id: `group.${groupId}`}],
      onQueryStarted: async (args, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(res => {
          res.data.forEach(action => {
            dispatch(
              adminApi.util.upsertQueryData(
                'scriptActionIndex',
                {actionId: action._id!, groupId: action.scriptGroupId, scriptId: action.scriptGroup?.scriptId || ''},
                action
              )
            );
          });
        });
      },
    },
    scriptActionIndex: {
      providesTags: args => [{type: 'ScriptActions', id: args?._id!}],
      onQueryStarted: async (args, {dispatch, queryFulfilled, getState}) => {
        queryFulfilled.then(res => {
          const template = adminApi.endpoints.templateIndex.select({templateId: res.data.templateId})(getState());
          if (res.data.template && template.isUninitialized) {
            dispatch(
              adminApi.util.upsertQueryData('templateIndex', {templateId: res.data.templateId}, res.data.template)
            );
          }
        });
      },
    },
    scriptActionsAdd: {
      invalidatesTags: (_data, _meta, {groupId}) => [{type: 'ScriptActions', id: `group.${groupId}`}],
    },
    scriptActionPatch: {
      // invalidatesTags: (_data, _meta, {actionId}) => [{type: 'ScriptActions', id: actionId}],
      onQueryStarted: ({scriptActionPartialDto, ...props}, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('scriptActionIndex', props, draft => {
            Object.assign(draft, scriptActionPartialDto);
          })
        );
        queryFulfilled
          .then(res => adminApi.util.upsertQueryData('scriptActionIndex', props, res.data))
          .catch(patch.undo);
      },
    },
    templateIndex: {
      providesTags: (_, __, {templateId}) => [{type: 'Templates', id: templateId}],
    },
    templateUpdate: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'Templates', id: templateId}],
    },
    templatePatch: {
      onQueryStarted: ({templatePartialDto, templateId}, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('templateIndex', {templateId}, draft => {
            Object.assign(draft, templatePartialDto);
          })
        );
        queryFulfilled
          .then(res => dispatch(adminApi.util.upsertQueryData('templateIndex', {templateId}, res.data)))
          .catch(patch.undo);
      },
    },
    templateOptionsIndex: {
      providesTags: (_data, _meta, args) => [{type: 'TemplateOptions', id: `template.${args.templateId}`}],
      onQueryStarted: (args, {dispatch, queryFulfilled}) =>
        queryFulfilled.then(res =>
          res.data.forEach(option =>
            dispatch(
              adminApi.util.upsertQueryData(
                'templateOptionIndex',
                {optionId: option._id!, templateId: args.templateId},
                option
              )
            )
          )
        ),
    },
    templateOptionsAdd: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'TemplateOptions', id: `template.${templateId}`}],
    },
    templateOptionsChangePositions: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'TemplateOptions', id: `template.${templateId}`}],
    },
    templateOptionIndex: {
      providesTags: (_data, _meta, {optionId}) => [{type: 'TemplateOptions', id: optionId}],
    },
    templateOptionPatch: {
      // invalidatesTags: (_data, _meta, {optionId}) => [{type: 'TemplateOptions', id: optionId}],
      onQueryStarted: ({templateOptionPartialDto, ...args}, {dispatch, queryFulfilled}) => {
        const patch = dispatch(
          adminApi.util.updateQueryData('templateOptionIndex', args, draft => {
            Object.assign(draft, templateOptionPartialDto);
          })
        );
        queryFulfilled.catch(patch.undo);
      },
    },
    templateOptionUpdate: {
      invalidatesTags: (_data, _meta, {optionId}) => [{type: 'TemplateOptions', id: optionId}],
    },
    scriptGroupsIndex: {
      providesTags: getListTags('ScriptGroups', (item: any) => item._id) as any,
    },
    scriptGroupsAdd: {
      invalidatesTags: () => [{type: 'ScriptGroups', id: '@@LIST'}],
    },
    scriptGroupIndex: {
      providesTags: data => [{type: 'ScriptGroups', id: data?._id}],
    },
    scriptGroupUpdate: {
      invalidatesTags: () => [{type: 'ScriptGroups', id: '@@LIST'}],
    },
    // SCRIPTS
    scriptsIndex: {
      providesTags: getListTags('Scripts', (item: any) => item._id) as any,
    },
    scriptsAdd: {
      invalidatesTags: () => [{type: 'Scripts', id: '@@LIST'}],
    },
    scriptIndex: {
      providesTags: data => [{type: 'Scripts', id: data?._id || '@@LIST'}],
    },
    scriptUpdate: {
      invalidatesTags: (_, __, {scriptId}) => [{type: 'Scripts', id: scriptId}],
    },
    scriptPatch: {
      invalidatesTags: (_, __, {scriptId}) => [{type: 'Scripts', id: scriptId}],
    },
    // CONTENTS
    contentsIndex: {
      providesTags: getListTags('Contents', (item: any) => item._id) as any,
    },
    contentsAdd: {
      invalidatesTags: () => [{type: 'Contents', id: '@@LIST'}],
    },
    contentIndex: {
      providesTags: data => [{type: 'Contents', id: data?._id}],
    },
    contentUpdate: {
      invalidatesTags: (_, __, {contentId}) => [{type: 'Contents', id: contentId}],
    },
    contentPatch: {
      onQueryStarted: async ({contentId}, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data) {
            dispatch(
              adminApi.util.updateQueryData('contentIndex', {contentId}, draft => {
                Object.assign(draft, result.data);
              })
            );
          }
        } catch (e) {}
      },
    },
    // CONTENT TAGS
    contentTagsSet: {
      invalidatesTags: (_, __, {contentId}) => [{type: 'ContentTags', id: contentId}],
    },
    contentTagsIndex: {
      providesTags: (_, __, {contentId}) => [{type: 'ContentTags', id: contentId}],
    },
    // CONTENT CATEGORIES
    contentsCategoriesIndex: {
      providesTags: () => ['ContentsCategories'],
    },
    contentsCategoriesAdd: {
      invalidatesTags: () => ['ContentsCategories'],
    },
    contentsCategoryUpdate: {
      invalidatesTags: () => ['ContentsCategories'],
    },
    // GROUPS
    groupsIndex: {
      providesTags: (_data, _meta, {subGroupsOf}) => [
        {type: 'Groups', id: subGroupsOf ? `subgroups.${subGroupsOf}` : '@@LIST'},
      ],
    },
    groupIndex: {
      providesTags: (_, __, {groupId}) => [{type: 'Groups', id: groupId}],
    },
    groupsAdd: {
      invalidatesTags: () => [{type: 'Groups', id: '@@LIST'}],
    },
    groupUpdate: {
      invalidatesTags: (_, __, {groupId}) => [
        {type: 'Groups', id: groupId},
        {type: 'Groups', id: `subgroups.${groupId}`},
      ],
    },
    groupPatch: {
      invalidatesTags: (_, __, {groupId}) => [
        {type: 'Groups', id: groupId},
        {type: 'Groups', id: `subgroups.${groupId}`},
      ],
    },
    groupUsers: {
      providesTags: getListTags('GroupUsers', (item: any) => item._id) as any,
    },
    groupAddUsers: {
      invalidatesTags: (data, meta, {body: {usersId}}) =>
        [{type: 'GroupUsers', id: '@@LIST'}].concat(
          usersId.map(userId => ({type: 'Users' as const, id: userId}))
        ) as any,
      onQueryStarted: ({body: {usersId}}, {queryFulfilled, dispatch}) => {
        queryFulfilled.then(() =>
          dispatch(usersApi.util.invalidateTags(usersId.map(userId => ({type: 'Users', id: userId}))))
        );
      },
    },
    groupDeleteUser: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    groupSetLeader: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    groupSetCurator: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    groupRemoveCurator: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    userLoginsAdd: {
      invalidatesTags: () => [{type: 'UserLogins', id: '@@LIST'}],
    },
    userLoginUpdate: {
      invalidatesTags: (_, __, {loginId}) => [{type: 'UserLogins', id: loginId}],
    },
    userLoginsIndex: {
      providesTags: getListTags('UserLogins', (item: any) => item._id) as any,
    },
    usersIndex: {
      providesTags: (data, meta, {streamId}) => [
        {type: 'Users', id: 'list'},
        {type: 'Users', id: `stream.${streamId?.join(',')}`},
      ],
      onQueryStarted: (_args, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(res => {
          res.data?.data?.map(user => dispatch(adminApi.util.upsertQueryData('userIndex', {userId: user._id!}, user)));
        });
      },
    },
    userIndex: {
      providesTags: data => [{type: 'Users', id: data?._id}],
    },
    userRestore: {
      invalidatesTags: (data, _, {userId}) => [{type: 'Users', id: userId}],
    },
    usersAdd: {
      invalidatesTags: () => [{type: 'Users', id: 'list'}],
    },
    userUpdate: {
      invalidatesTags: (_, __, {userId}) => [{type: 'Users', id: userId}],
      onQueryStarted: async ({userId}, {dispatch, queryFulfilled}) => {
        queryFulfilled.then(() => dispatch(usersApi.util.invalidateTags([{type: 'Users', id: userId}])));
      },
    },
    // PERMISSIONS
    userPermissionsIndex: {
      providesTags: (_, __, {userId}) => [{type: 'UsersPermissions', id: userId}],
    },
    userPermissionsUpdate: {
      invalidatesTags: (_, __, {userId}) => [{type: 'UsersPermissions', id: userId}],
    },
    // TAGS
    tagsIndex: {
      providesTags: getListTags('Tags', (item: any) => item._id) as any,
    },
    tagsAdd: {
      invalidatesTags: () => [{type: 'Tags', id: '@@LIST'}],
    },
    tagUpdate: {
      invalidatesTags: (_, __, {tagId}) => [{type: 'Tags', id: tagId}],
    },
    // REACTIONS BLOCK
    reactionsBlocksIndex: {
      providesTags: ['ReactionsBlocks'],
    },
    reactionsBlockIndex: {
      providesTags: ['ReactionsBlocks'],
    },
    reactionsBlocksAdd: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockUpdate: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockPatch: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockDelete: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    // STAGE POINTS
    stagePointPatch: {
      onQueryStarted: async ({pointId}, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result?.data) {
            dispatch(
              usersApi.util.updateQueryData('stagePointsIndex', {stageId: result.data.stageId}, draft => {
                const index = draft.findIndex(item => item.stagePoint?._id === pointId);
                if (index !== -1) {
                  draft.splice(index, 1, {...draft[index], stagePoint: result.data});
                }
              })
            );
          }
        } catch (e) {}
      },
    },
  },
}) as unknown as typeof rawAdminApi;

export const {useTagUpdateMutation, useTagsAddMutation} = adminApi;
