import {UploadSingleFile} from '__theme/components/upload';
import {filesApi} from 'api';
import {FileIdProp} from 'modules/files/types';
import {FC, useCallback, useState} from 'react';
import {filePath} from 'utils';

export const MakeVideoPreview: FC<FileIdProp> = ({fileId}) => {
  const [previewFileId, setPreviewFileId] = useState<string | undefined>();
  const [upload] = filesApi.endpoints.rootUpload.useMutation();
  const onDrop = useCallback(
    async (files: File[]) => {
      const body = new FormData();
      files.forEach(file => body.append('file', file));
      const result = await upload({body: body as any, relationId: fileId, relationType: 'preview'});
      if ('data' in result) setPreviewFileId(result.data[0]._id);
    },
    [upload, fileId]
  );

  return (
    <UploadSingleFile
      onDrop={onDrop}
      accept="image/*"
      sx={{height: 150}}
      file={null}
      fileId={previewFileId}
      filePath={previewFileId ? undefined : filePath(fileId, 'preview')}
    />
  );
};
