import _ from 'lodash';
import {FC} from 'react';
import {adminApi, usersApi} from 'api';
import {Autocomplete, Card, CardContent, TextField, createFilterOptions} from '@mui/material';

import {PostIdProp} from 'modules/dashboard/types';
import {Tags} from '@apiSchema/admin-api';

const filter = createFilterOptions<Tags>();

export const PublicationTags: FC<PostIdProp> = ({postId}) => {
  const {tagOptions} = adminApi.endpoints.tagsIndex.useQuery(
    {type: 'publication'},
    {selectFromResult: ({data}) => ({tagOptions: data?.data || []})}
  );
  const {defaultValue, refetch} = usersApi.endpoints.publicationIndex.useQuery(
    {postId},
    {
      selectFromResult: ({data}) => ({
        defaultValue: (data?.tagsSet || []).map(item => item.tag),
      }),
      refetchOnMountOrArgChange: true,
    }
  );

  const [createTag] = adminApi.endpoints.tagsAdd.useMutation();
  const [addTag] = adminApi.endpoints.publicationTagsSet1.useMutation();
  const addTags = async (tags: Tags[]) => {
    await addTag({
      postId,
      body: {
        tagsId: _.uniq(
          tags
            .filter(Boolean)
            .map(item => item._id)
            .filter(Boolean)
        ) as string[],
      },
    });
    refetch();
  };
  const addNewTag = async (tag: Tags) => {
    await addTag({
      postId,
      body: {tagsId: _.uniq([...defaultValue.filter(Boolean).map(item => item?._id!), tag._id!])},
    });
    refetch();
  };
  const addNewOption = async (name: string) => {
    const result = await createTag({tagDto: {name, type: 'publication', enabled: true}});
    if ('data' in result) addNewTag(result.data);
  };

  return (
    <Card>
      <CardContent>
        <Autocomplete
          multiple
          selectOnFocus
          freeSolo
          clearOnBlur
          key={postId && JSON.stringify(defaultValue)}
          options={tagOptions}
          defaultValue={defaultValue as any}
          getOptionLabel={item => (typeof item === 'string' ? item : (item as any).name)}
          renderInput={params => <TextField {...params} label="Теги поста" placeholder="Выберите или создайте тег" />}
          onChange={async (event, newValue) => {
            const newOption = (newValue?.find(item => typeof item !== 'string' && 'inputValue' in (item as any)) as any)
              ?.inputValue;
            if (newOption && typeof newOption === 'string') addNewOption(newOption);
            if (newValue) addTags(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const {inputValue} = params;
            const isExisting = options.some(option => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                name: `Создать тег «${inputValue}»`,
              } as any);
            }
            return filtered;
          }}
        />
      </CardContent>
    </Card>
  );
};
