import {FC} from 'react';
import Card from '@mui/material/Card';

import {usersApi} from 'api';

import {UserInfo} from 'modules/users';
import {PostIdProp} from 'modules/dashboard/types';

export const PublicationAuthorInfo: FC<PostIdProp> = ({postId}) => {
  const {userId} = usersApi.endpoints.publicationIndex.useQuery(
    {postId},
    {
      selectFromResult: ({data}) => ({
        userId: data?.userId,
      }),
    }
  );
  if (!userId) return null;
  return (
    <Card key={userId}>
      <UserInfo userId={userId} />
    </Card>
  );
};
