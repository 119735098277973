import {adminApi} from 'api';
import {ArrayParam, BooleanParam, StringParam, useQueryParams, withDefault} from 'use-query-params';

export const useGetAllStreamsId = () => {
  const {streamId, isLoading} = adminApi.endpoints.streamsIndex.useQuery(
    {limit: 10000},
    {
      selectFromResult: ({data, isLoading}) => ({
        isLoading,
        streamId: data?.data?.map(item => item._id!) || [],
      }),
    }
  );

  return {streamId, isLoading};
};

const queryShape = {
  tagId: ArrayParam,
  questId: StringParam,
  widgetId: StringParam,
  notViewedByCurator: BooleanParam,
  notCommentedByCurator: BooleanParam,
  sort: withDefault(StringParam, 'updated_date'),
};

export const usePublicationsListQuery = () => useQueryParams(queryShape);
