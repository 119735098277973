import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';

import {useGetContent} from './pages/ContentLayout/hook';
import {Contents} from '@apiSchema/admin-api';

const ContentLayout = lazy(() => import('./pages/ContentLayout'));
const ContentListPage = lazy(() => import('./pages/ContentList'));
const ContentItemPage = lazy(() => import('./pages/ContentItem'));
const ContentSettingsPage = lazy(() => import('./pages/ContentSettings'));
const ContentsCategoryListPage = lazy(() => import('./pages/ContentsCategoryList'));

const router: RouteObject = {
  path: 'contents',
  element: (
    <AccessGuard endpoint="contentsIndex">
      <BreadcrumbsSet name="Материалы" href={PATH_APP.tasks.contents.root} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {
      path: PATH_APP.tasks.contents.categories,
      element: (
        <AccessGuard endpoint="contentsCategoriesIndex">
          <BreadcrumbsSet name="Категории материалов" href={PATH_APP.catalogs.contentCategories}>
            <ContentsCategoryListPage />
          </BreadcrumbsSet>
        </AccessGuard>
      ),
    },
    {
      path: 'list',
      children: [
        {
          index: true,
          element: <ContentListPage />,
        },
        {
          element: <ContentLayout />,
          children: [
            {
              path: 'create',
              element: (
                <AccessGuard endpoint="contentsAdd">
                  <BreadcrumbsSet name="Новый материал" />
                </AccessGuard>
              ),
              children: [{index: true, element: <ContentItemPage />}],
            },
            {
              path: ':contentId',
              element: (
                <AccessGuard endpoint="contentIndex">
                  <IsExistsContainer<Contents> useFetch={useGetContent}>
                    {content => <BreadcrumbsSet name={content.name || 'Новый материал'} />}
                  </IsExistsContainer>
                </AccessGuard>
              ),
              children: [
                {
                  index: true,
                  element: <ContentItemPage />,
                },
                {
                  path: 'settings',
                  element: <ContentSettingsPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default router;
