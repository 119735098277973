import {createSelector} from '@reduxjs/toolkit';
import {skipToken} from '@reduxjs/toolkit/query';
import {shallowEqual, useSelector} from 'react-redux';

import {usersApi} from 'api';

import {PostIdProp} from './types';

export const selectPost = createSelector(
  (state: any) => state,
  (_: any, postId: string) => postId,
  (state: any, postId: string) => {
    const data = usersApi.endpoints.publicationIndex.select({postId})(state).data;
    if (!data) throw new Error('no post found');
    return data;
  }
);

export const selectPostStageId = createSelector(selectPost, post => ({
  stageId: post.attachments?.find(item => item.referenceName === 'Stages' && item.enabled)?.referenceId,
}));

export const selectPostAchievmentId = createSelector(selectPost, post => ({
  achievmentId: post.attachments?.find(item => item.referenceName === 'Achievements' && item.enabled)?.referenceId,
}));

export const useGetStageInfo = ({postId}: PostIdProp) => {
  const {stageId} = useSelector<any>(state => selectPostStageId(state, postId), shallowEqual) as ReturnType<
    typeof selectPostStageId
  >;
  const {questName, questType, questFileId, questId, isLoading} = usersApi.endpoints.stageIndex.useQuery(
    stageId ? {stageId, partial: true} : skipToken,
    {
      selectFromResult: ({data, isLoading, isUninitialized}) => ({
        isLoading: isLoading || isUninitialized,
        questName: data?.stage.quest?.design?.hint || data?.stage.quest?.design?.header || data?.stage.quest?.name,
        questType: data?.stage.questType,
        questId: data?.stage.questId,
        questFileId: data?.stage.quest?.design?.fileId,
      }),
    }
  );

  return {questName, questType, isLoading, questFileId, questId, stageId};
};
